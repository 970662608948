// Core
import React, { FC, useEffect, useRef, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Global file and config
import { getServerDataValue } from '../../../../../../../new-moyo/src/js/native/helpers/storage.js';
// Parts
import Input from '../../../../components/_Form/Input';
import Button from '../../../../components/Button';
import useStyles from '../../styles';
// Engine
import {
  postOrderStatusAsync,
} from '../../../../../engine/core/orderStatus/saga/asyncAction';
import { selectorsOrderStatus } from '../../../../../engine/core/orderStatus/selectors';
import { clearValue as clearValueAction, setError } from '../../../../../engine/core/orderStatus/slice';
import {
  composeValidators,
  isRequired,
  isOrderNumberValid,
  isOrderNumberLengthValid
} from '../../../../components/_Form/validators';

export const InfoFormNames = Object.freeze({
  number: 'number',
});

const ORDER_LENGTH = {
  ORDER_NUMBER_MIN_LENGTH: 10,
  ORDER_NUMBER_MAX_LENGTH: 12,
}

export const InfoForm: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isAuth, setIsAuth] = useState(true)
  const form = useRef<HTMLFormElement | null>(null);
  const { t } = useTranslation();
  const loading = useSelector(selectorsOrderStatus.loading);
  const error = useSelector(selectorsOrderStatus.error);
  const clearValue = useSelector(selectorsOrderStatus.clearValue);
  const params = (new URL(window.location.href)).searchParams;
  const orderId = params.get('orderId');
  const prevOrderId = useRef(orderId);

  const onSubmit = (value: { [key: string]: string; }) => {
    const { [InfoFormNames.number]: orderNumber } = value;
    dispatch(postOrderStatusAsync({ 'Order[orderNumber]': orderNumber }))
  }

  const onValidate = () => (
    { [InfoFormNames.number]: error }
  );
  const inputOnChange = () => {
    if (error) {
      dispatch(setError(undefined))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const headerInfoData = getServerDataValue('headerInfo') || {};
      const defaultIsAuth = !!headerInfoData.userId;
      setIsAuth(defaultIsAuth)
    }, 500);
  }, [])

  useEffect(() => {
    if (prevOrderId.current && orderId && orderId === prevOrderId.current && form?.current) {
      form.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }, [dispatch, orderId])

  useEffect(() => {
    if (clearValue) {
      window.orderForm?.mutators?.clearFormValue(InfoFormNames.number, undefined);
      dispatch(clearValueAction(false));
    }
  }, [clearValue, dispatch])

  useEffect(() => () => { window.orderForm = undefined }, []);

  return (
    <Form
      onSubmit={onSubmit}
      validate={onValidate}
      mutators={{
        clearFormValue: (args, state) => {
          state.fields[args[0]]?.change(args[1]);
        }
      }}
      render={(
        {
          handleSubmit,
          submitting,
          pristine,
          values,
          form: orderForm,
        }
      ) => {
        if (!window.orderForm) {
          window.orderForm = orderForm;
        }

        return <form ref={form} onSubmit={handleSubmit} className={classes.wrapper}>
          <Field
            name={InfoFormNames.number}
            onChange={inputOnChange}
            component={Input}
            maxLength={ORDER_LENGTH.ORDER_NUMBER_MAX_LENGTH}
            defaultValue={orderId || undefined}
            label={t('orderStatus.infoForm.number')}
            validate={composeValidators(isRequired, isOrderNumberValid)}
          />
          <Button
            fullWidth
            disabled={
              submitting || pristine || loading || !isOrderNumberLengthValid(values[InfoFormNames.number], Object.values(ORDER_LENGTH))
            }
            loading={loading}
            submit
          >
            {t('orderStatus.infoForm.button')}
          </Button>
          {!isAuth && (
            <div className="order-status_check_info">
              {t('orderStatus.infoForm.info')}
              <span className="link js-auth-modal-show">{t('orderStatus.infoForm.infoAuth')}</span>
            </div>
          )}
        </form>
      }}
    />
  )
}
